import { noop } from 'lodash';
import { VIEW_MODES } from '@wix/wix-vod-constants/dist/common/view-modes';
import { playbackCorrelationChain } from '../../bi/correlation/playback';
import { getModalUrl } from '@wix/yoshi-flow-editor/utils';

export function createOpenOverlayHandlers({
  wixCodeApi,
  compId,
  appParams: { instance },
}) {
  const { scrollTo, viewMode } = wixCodeApi.window;

  async function openPopup(path, width, height, theme, onClose = noop) {
    const { scroll } = await wixCodeApi.window.getBoundingRect();

    const result = await wixCodeApi.window.openPopup(
      path,
      {
        compId,
        width,
        height,
        position: {
          origin: 'FIXED',
          placement: 'CENTER',
        },
        theme,
      },
      compId,
    );

    onClose(result && result.message && result.message.reason);
    scrollTo(scroll.x, scroll.y);
  }

  const openOverlay =
    (entry) =>
    async (hash = '', onClose = noop) => {
      const url = new URL(entry);
      url.hash = hash;
      url.searchParams.append('rootCompId', compId);
      url.searchParams.append('rootPageId', wixCodeApi.site.currentPage.id);
      url.searchParams.append(
        'correlationId',
        playbackCorrelationChain.getLastCorrelationId(),
      );

      await openPopup(url.href, '100%', '100%', 'BARE', onClose);
    };

  return {
    openMobileOverlay: (hash, onClose) => {
      if (viewMode === VIEW_MODES.PREVIEW) {
        scrollTo(0, 0);
      }

      openOverlay(getModalUrl('WixVideo', 'MobileLightbox', instance))(
        hash,
        onClose,
      );
    },
    openFullScreenOverlay: openOverlay(
      getModalUrl('WixVideo', 'DesktopLightbox', instance),
    ),
  };
}
