import _ from 'lodash';
import { BI_SOURCES } from '@wix/wix-vod-constants/dist/bi/sources';
import events from './events.json';

import { playbackCorrelationChain } from './correlation/playback';

export function createVodBiLogger(factory, defaultParams = {}, debug) {
  const knownEvents = _.mapValues(events, (evid) => ({ evid }));
  const biFactory = factory({ endpoint: 'video' });

  biFactory
    .updateDefaults({
      src: BI_SOURCES.VOD_SRC_ID,
      ...defaultParams,
    })
    .setEvents(knownEvents);

  biFactory.setMuted(debug);

  const logger = biFactory.logger();

  return {
    log: async (eventName, params) => {
      const evid = events[eventName];
      const correlationId =
        playbackCorrelationChain.getCorrelationId(eventName);
      const event = {
        evid,
        ...params,
        ...(correlationId && { correlationId }),
      };

      await logger.log(event, { endpoint: 'video' });
    },
  };
}
