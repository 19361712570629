import { IWixWindow } from '@wix/yoshi-flow-editor';
import { PublicChannelItem, PublicVideoItem } from '@wix/wix-vod-api/public';
import { PublicChannelItem as PublicChannelItemV2 } from '@wix/wix-vod-api-site-based/dist/src/public/services/channel/channel';
import type { IHttpClient } from '@wix/http-client';
import { dashify } from '@wix/wix-vod-shared/dist/src/common/utils/guid';
import { PRODUCTION_HOSTS } from '@wix/wix-vod-constants/dist/env/hosts';
import { PublicChannelService } from '@wix/wix-vod-gql-api/public';
import { getPublicApi } from '../../api/public';
import { MEDIA_TYPES } from '@wix/wix-vod-constants/dist/common/media-types';
import { YoutubeChannelVideosResponse } from '@wix/wix-vod-api-site-based/dist/src/public/services/youtube/youtube';
import { VideoListResponse } from '@wix/wix-vod-api-site-based/dist/src/public/services/channel-videos/channel-videos';
import { YoutubeVideo } from '@wix/wix-vod-api-site-based/dist/src/public/services/youtube/youtube.types';
import { URL_TYPES } from '@wix/wix-vod-constants/dist/common/url-types';
import { VideoResponse } from '@wix/wix-vod-api-site-based/dist/src/private/services/video/video.types';

type ViewMode = IWixWindow['viewMode']; // can not import the proper type;

type WidgetDataVideos = { items: PublicVideoItem[] };
type WidgetDataVideosV2 = { items: (PublicVideoItem | YoutubeVideo)[] };

interface BaseWidgetDataV3 {
  isV3Api: true;
  __TRANSLATIONS__: Record<string, string>;
}

interface SingleVideoWidgetData extends BaseWidgetDataV3 {
  isSingleVideo: true;
  __SINGLE_VIDEO__: PublicVideoItem;
  __CHANNEL__: {};
}

interface ChannelVideoWidgetData extends BaseWidgetDataV3 {
  isSingleVideo: false;
  __CHANNEL__: PublicChannelItem;
  __VIDEOS__: WidgetDataVideos;
}

export type WidgetData = SingleVideoWidgetData | ChannelVideoWidgetData;

export type WidgetDataV2 = {
  isV3Api: false;
  __TRANSLATIONS__: Record<string, string>;
  __CHANNEL__: PublicChannelItem | PublicChannelItemV2;
  __VIDEOS__?: VideoListResponse | YoutubeChannelVideosResponse;
  __SINGLE_VIDEO__?: VideoResponse | YoutubeVideo | undefined;
};

interface GetWidgetDataQueryParams {
  channelId: string;
  videoId?: string;
  instance: string;
  locale?: string;
  videosCount: number;
  isV3Api: boolean;
  metaSiteId?: string;
}

interface GetWidgetDataOptions {
  baseUrl: string;
  viewMode: ViewMode;
  httpClient: IHttpClient;
  translations: Record<string, string>;
  queryParams: GetWidgetDataQueryParams;
}

const mapOldYoutubeResponseToVideos = (
  youtubeResponse: YoutubeChannelVideosResponse,
): WidgetDataVideosV2 => {
  return {
    items: youtubeResponse.videoItems,
  };
};

const getWidgetDataV2 = async ({
  queryParams,
  translations,
}: GetWidgetDataOptions): Promise<WidgetDataV2> => {
  const { channelId, videoId, videosCount } = queryParams;
  const publicApi = getPublicApi();
  const channel = (await publicApi.oldPublicChannelService.getInfo(channelId))
    .data;

  const widgetData: WidgetDataV2 = {
    isV3Api: false,
    __TRANSLATIONS__: translations,
    __CHANNEL__: channel,
  };

  if (videoId) {
    widgetData.__SINGLE_VIDEO__ = (
      await publicApi.oldPublicChannelVideos.getChannelVideoById(
        channelId,
        videoId,
      )
    ).data;
  } else {
    widgetData.__VIDEOS__ = channel.external_id
      ? (
          await publicApi.oldYoutubeService.getChannelVideos({
            externalId: channel.external_id,
            urlType: channel.url_type as URL_TYPES.CHANNEL | URL_TYPES.PLAYLIST,
            itemsCount: videosCount,
          })
        ).data
      : (
          await publicApi.oldPublicChannelVideos.getList({
            channelId,
            paging: {
              size: videosCount,
            },
            mediaType: MEDIA_TYPES.SECURE_VIDEO,
          })
        ).data;
  }

  return widgetData;
};

const getWidgetDataV3 = async ({
  queryParams,
  baseUrl,
  viewMode,
  translations: __TRANSLATIONS__,
}: GetWidgetDataOptions): Promise<WidgetData> => {
  const { videosCount, instance } = queryParams;
  const videoId = dashify(queryParams.videoId);
  const channelId = dashify(queryParams.channelId);
  if (!channelId) {
    throw Error('can not get widget data, no channel id');
  }

  const channelService = new PublicChannelService(
    instance,
    viewMode === 'Site' ? baseUrl : PRODUCTION_HOSTS.manage,
  );
  const widgetData = await channelService.getWidgetData({
    channelId,
    videosCount: Number(videosCount),
    singleVideoId: dashify(videoId) || null,
  });
  const baseData: BaseWidgetDataV3 = {
    isV3Api: true,
    __TRANSLATIONS__,
  };
  if (widgetData.isSingleVideo) {
    return {
      isSingleVideo: true,
      __SINGLE_VIDEO__: widgetData.singleVideo,
      __CHANNEL__: {},
      ...baseData,
    };
  }

  const { videos, channel } = widgetData;
  return {
    isSingleVideo: false,
    __VIDEOS__: videos,
    __CHANNEL__: channel,
    ...baseData,
  };
};

export const getWidgetData = async (
  options: GetWidgetDataOptions,
): Promise<WidgetData | WidgetDataV2> => {
  const { isV3Api } = options.queryParams;
  return isV3Api ? getWidgetDataV3(options) : getWidgetDataV2(options);
};
