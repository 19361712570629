import { ControllerParams } from '@wix/yoshi-flow-editor';
import { createAnnotoHandlers, AnnotoHandlers, AnnotoState } from './annoto';
import { createLiveHandlers, LiveHandlers } from './live';
import {
  createPricingPlanHandlers,
  PricingPlanHandlers,
  PricingPlanState,
} from './pricing-plan';
import { CollectorHandlers, createCollectorHandlers } from './collector';

export type Handlers = AnnotoHandlers &
  LiveHandlers &
  PricingPlanHandlers &
  CollectorHandlers;
export type ControllerState = AnnotoState & PricingPlanState;
export type HandlersWithState = {
  handlers: Handlers;
  getControllerState: () => ControllerState;
};

export function createHandlers(context: ControllerParams): HandlersWithState {
  let controllerState: ControllerState;
  const { controllerConfig } = context;
  const setState = (state: Partial<ControllerState>) => {
    controllerState = {
      ...controllerState,
      ...state,
    };
    controllerConfig.setProps({ controllerState });
  };

  return {
    getControllerState: () => controllerState,
    handlers: {
      ...createAnnotoHandlers(setState, context),
      ...createLiveHandlers(context),
      ...createPricingPlanHandlers(setState, context),
      ...createCollectorHandlers(context),
    },
  };
}
