import { ControllerParams } from '@wix/yoshi-flow-editor';
import { PricingPlansAPI } from '@wix/wix-vod-pricing-plans-api';
import { dashify } from '@wix/wix-vod-shared/dist/src/common/utils/guid';

export type PricingPlanHandlers = {
  requestPricingPlanPaymentInfo: (channelId: string, userId: string) => void;
};

export type PricingPlanState = {
  hasAccessToChannelByPricingPlan?: boolean;
};

export function createPricingPlanHandlers(
  setState: (state: PricingPlanState) => void,
  context: ControllerParams,
): PricingPlanHandlers {
  const {
    flowAPI: { httpClient },
  } = context;

  return {
    requestPricingPlanPaymentInfo: async (
      channelId: string,
      userId: string,
    ) => {
      const pricingPlanService = new PricingPlansAPI(httpClient);
      const hasAccessToChannelByPricingPlan =
        await pricingPlanService.hasAccessToChannel(
          dashify(channelId) as string,
          userId,
        );

      const state: PricingPlanState = {
        hasAccessToChannelByPricingPlan,
      };

      setState(state);
    },
  };
}
